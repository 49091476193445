/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PdfKind } from '../models/pdf-kind';
import { PrintoutLinkInfo } from '../models/printout-link-info';

@Injectable({
  providedIn: 'root',
})
export class PdfService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrderXml
   */
  static readonly GetOrderXmlPath = '/api/v1/packaging/printout/{imos_order_no}/xml';

  /**
   * Get Order Xml.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderXml()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderXml$Response(params: {
    imos_order_no: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PdfService.GetOrderXmlPath, 'get');
    if (params) {
      rb.path('imos_order_no', params.imos_order_no, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Order Xml.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderXml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderXml(params: {
    imos_order_no: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.getOrderXml$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getPrintoutPdf
   */
  static readonly GetPrintoutPdfPath = '/api/v1/packaging/printout/{imos_order_no}/{kind}';

  /**
   * Get Pdf Of Type For Order Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrintoutPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintoutPdf$Response(params: {
    imos_order_no: string;
    kind: PdfKind;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PdfService.GetPrintoutPdfPath, 'get');
    if (params) {
      rb.path('imos_order_no', params.imos_order_no, {});
      rb.path('kind', params.kind, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Pdf Of Type For Order Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrintoutPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintoutPdf(params: {
    imos_order_no: string;
    kind: PdfKind;
    context?: HttpContext
  }
): Observable<any> {

    return this.getPrintoutPdf$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getPrintout
   */
  static readonly GetPrintoutPath = '/api/v1/packaging/printout';

  /**
   * Get List Of Orders Which Can Be Printed Out.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrintout()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintout$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PrintoutLinkInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, PdfService.GetPrintoutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PrintoutLinkInfo>>;
      })
    );
  }

  /**
   * Get List Of Orders Which Can Be Printed Out.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrintout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintout(params?: {
    context?: HttpContext
  }
): Observable<Array<PrintoutLinkInfo>> {

    return this.getPrintout$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PrintoutLinkInfo>>) => r.body as Array<PrintoutLinkInfo>)
    );
  }

}
