import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SidebarModule } from 'primeng/sidebar';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/app/shared/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { CrudDataEditorModule, CrudDataTableModule } from '@gr/gr-component-library';
import { SalesAgentOverviewComponent } from './sales-agent-overview/sales-agent-overview.component';
import { CustomDropdownComponent } from './shared/formly-components/dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormlyModule } from '@ngx-formly/core';
import { CalendarModule } from 'primeng/calendar';
import localeDe from '@angular/common/locales/de';

// Registering german locale, so the decimal pipe formats the price in the ui according to the requirements.
registerLocaleData(localeDe);

function initializeKeycloak(keycloak: KeycloakService) {
  return (): Promise<boolean> => {
    return new Promise((resolve) => {
      // If we can not fetch on the keycloaks realm endpoint we probably have not a working auth structure in place
      // Not having this would lead to an infinite keycloak init and breaking the whole SPA
      // those we fallback to not having keycloak in place to at least have other features working,
      // although the AdminPanel won't be reachable than.
      fetch(`${environment.keycloak.serverUrl}/realms/${environment.keycloak.realmName}/`)
        .then(() => {
          keycloak
            .init({
              config: {
                url: environment.keycloak.serverUrl,
                realm: environment.keycloak.realmName,
                clientId: environment.keycloak.clientId,
              },
              initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
              },
              shouldAddToken: (request) => {
                const { url } = request;
                const urls = ['/api/v1/admin', '/api/v1/packaging', '/api/v1/b2b'];
                const isAcceptablePathMatch = urls.some((path) => url.includes(path));

                return isAcceptablePathMatch;
              },
            })
            .then(() => resolve(true));
        })
        .catch((e) => {
          console.error('Error:', e);
          resolve(true);
        });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AdminPanelComponent,
    SalesAgentOverviewComponent,
    CustomDropdownComponent,
  ],
  imports: [
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormlyPrimeNGModule,
    ToolbarModule,
    ButtonModule,
    InputSwitchModule,
    SidebarModule,
    ListboxModule,
    TableModule,
    CheckboxModule,
    ToastModule,
    ChipModule,
    DialogModule,
    KeycloakAngularModule,
    HttpClientModule,
    RouterModule,
    CrudDataEditorModule,
    CrudDataTableModule,
    ProgressSpinnerModule,
    CalendarModule,
    CrudDataTableModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'dropdown',
          component: CustomDropdownComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
