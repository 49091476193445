/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConfigurationTypeResponse } from '../models/configuration-type-response';
import { CupboardData } from '../models/cupboard-data';
import { Order } from '../models/order';
import { TotalPrice } from '../models/total-price';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getConfiguration
   */
  static readonly GetConfigurationPath = '/api/v1/configuration/{configuration_id}';

  /**
   * Get Cupboard Configuration Json.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration$Response(params: {
    configuration_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.GetConfigurationPath, 'get');
    if (params) {
      rb.path('configuration_id', params.configuration_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Cupboard Configuration Json.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration(params: {
    configuration_id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.getConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getConfigurationTypes
   */
  static readonly GetConfigurationTypesPath = '/api/v1/configuration-type';

  /**
   * Get Cupboard Configuration Types And Ids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigurationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationTypes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ConfigurationTypeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.GetConfigurationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigurationTypeResponse>;
      })
    );
  }

  /**
   * Get Cupboard Configuration Types And Ids.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConfigurationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationTypes(params?: {
    context?: HttpContext
  }
): Observable<ConfigurationTypeResponse> {

    return this.getConfigurationTypes$Response(params).pipe(
      map((r: StrictHttpResponse<ConfigurationTypeResponse>) => r.body as ConfigurationTypeResponse)
    );
  }

  /**
   * Path part for operation postForConfigurationPrice
   */
  static readonly PostForConfigurationPricePath = '/api/v1/configuration_price';

  /**
   * Calculate Price For Provided Cupboard Configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postForConfigurationPrice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postForConfigurationPrice$Response(params: {
    context?: HttpContext
    body: CupboardData
  }
): Observable<StrictHttpResponse<TotalPrice>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.PostForConfigurationPricePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TotalPrice>;
      })
    );
  }

  /**
   * Calculate Price For Provided Cupboard Configuration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postForConfigurationPrice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postForConfigurationPrice(params: {
    context?: HttpContext
    body: CupboardData
  }
): Observable<TotalPrice> {

    return this.postForConfigurationPrice$Response(params).pipe(
      map((r: StrictHttpResponse<TotalPrice>) => r.body as TotalPrice)
    );
  }

  /**
   * Path part for operation postForXml
   */
  static readonly PostForXmlPath = '/api/v1/xml';

  /**
   * Return Xml For Provided Cupboard Configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postForXml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postForXml$Response(params: {
    context?: HttpContext
    body: CupboardData
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.PostForXmlPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Return Xml For Provided Cupboard Configuration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postForXml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postForXml(params: {
    context?: HttpContext
    body: CupboardData
  }
): Observable<string> {

    return this.postForXml$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation putConfiguration
   */
  static readonly PutConfigurationPath = '/api/v1/configuration';

  /**
   * Upload Cupboard Configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConfiguration$Response(params: {
    context?: HttpContext
    body: Order
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.PutConfigurationPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Upload Cupboard Configuration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConfiguration(params: {
    context?: HttpContext
    body: Order
  }
): Observable<any> {

    return this.putConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation ordered
   */
  static readonly OrderedPath = '/api/v1/ordered';

  /**
   * Ordered Configuration Ids Linked To Product Ids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ordered()` instead.
   *
   * This method doesn't expect any request body.
   */
  ordered$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersService.OrderedPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Ordered Configuration Ids Linked To Product Ids.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ordered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ordered(params?: {
    context?: HttpContext
  }
): Observable<any> {

    return this.ordered$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
