import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/keycloak-guard';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { SalesAgentOverviewComponent } from './sales-agent-overview/sales-agent-overview.component';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: AdminPanelComponent,
  },
  {
    path: 'agent',
    canActivate: [AuthGuard],
    component: SalesAgentOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
