<div class="navigation-container">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <p-button
        icon="pi pi-bars"
        (click)="display = true"
        class="p-button-raised p-button-text"
      ></p-button>
    </div>

    <div class="p-toolbar-group-right">
      <p-button (click)="logout()" label="Logout"></p-button>
    </div>
  </p-toolbar>

  <p-sidebar [(visible)]="display">
    <ul>
      <li
        *ngFor="let item of navItems"
        (click)="sideNavClick(item.name)"
        [ngClass]="item.name === itemToShow ? 'active' : ''"
      >
        {{ item.text }}
        <ul class="categories" *ngIf="item.name === 1">
          <li
            *ngFor="let category of categoriesList"
            (click)="getArticles(category)"
            [ngClass]="category === subItemToShow ? 'subActive' : ''"
          >
            {{ category }}
          </li>
        </ul>
      </li>
    </ul>
  </p-sidebar>
</div>

<article>
  <div class="form" *ngIf="itemToShow === 0">
    <h1>Global constants</h1>
    <gr-crud-data-editor
      #globalConstantsEditor
      [config]="defaultValuesConfig"
      [item]="defaultValues"
      (buttonClickEmitter)="ondefaultValuesSubmit($event)"
    ></gr-crud-data-editor>
  </div>

  <div class="form" style="min-width: 50vw" *ngIf="itemToShow === 1">
    <h1>{{ subItemToShow | titlecase }} Articles</h1>
    <div style="display: flex">
      <p-listbox
        [listStyle]="{ 'max-height': '60vh' }"
        [options]="articlesList"
        [(ngModel)]="currentArticle"
        optionLabel="display_name"
        (onChange)="getArticleValueConstant()"
      ></p-listbox>

      <div style="width: 100%">
        <div class="form-wrapper">
          <gr-crud-data-editor
            #currentConstantsEditor
            [config]="articleValuesConfig"
            [item]="articleValues"
            (buttonClickEmitter)="onArticleValuesSubmit(currentArticle.name, $event)"
          ></gr-crud-data-editor>
        </div>
      </div>
    </div>
  </div>

  <div class="form" style="min-width: 80vw" *ngIf="itemToShow === 2">
    <h1>Orders</h1>
    <gr-crud-data-table [config]="orderTableConfig" [items]="orderItems">
      <ng-template #header let-name>
        <p-button
          *ngIf="name === 'Batch'; else defaultTemplate"
          id="batch-download"
          styleClass="p-button"
          icon="pi pi-download"
          label="Batch"
          (click)="batchDownload()"
        ></p-button>
        <ng-template #defaultTemplate>{{ name }}</ng-template>
      </ng-template>

      <ng-template #item let-value let-item="item" let-id="id">
        <ng-container *ngIf="id === 'batchDownload'">
          <p-checkbox
            class="checkbox-alignment"
            [binary]="true"
            [(ngModel)]="value.shouldBatchDownload"
          >
          </p-checkbox>
        </ng-container>

        <span *ngIf="id === 'imos_order_no'">{{ value }}</span>

        <span *ngIf="id === 'timeStamp'">{{ value }}</span>

        <p-button
          *ngIf="id === 'boardsPdf'"
          class="download-button"
          icon="pi pi-download"
          (click)="downloadPdf(item, pdfKind.Boards)"
        ></p-button>

        <p-button
          *ngIf="id === 'hardwarePdf'"
          class="download-button"
          icon="pi pi-download"
          (click)="downloadPdf(item, pdfKind.Hardware)"
        ></p-button>

        <p-button
          *ngIf="id === 'manualPdf'"
          class="download-button"
          icon="pi pi-download"
          (click)="downloadPdf(item, pdfKind.Manual)"
        ></p-button>

        <p-button
          *ngIf="id === 'xml'"
          class="download-button"
          icon="pi pi-download"
          (click)="downloadXML(item)"
        ></p-button>
      </ng-template>
    </gr-crud-data-table>
  </div>

  <ng-container *ngIf="itemToShow === 3">
    <div [ngSwitch]="isB2BAdmin">
      <div *ngSwitchCase="false">
        <div
          class="form"
          style="min-width: 80vw; font-family: 'Zen Kaku Gothic Antique'; font-size: 1.9rem"
        >
          Your user currently has no permissions to access this page.
        </div>
      </div>
      <div *ngSwitchDefault>
        <div class="form b2badmin" style="min-width: 80vw">
          <p-toolbar>
            <div class="p-toolbar-group-right">
              <p-button
                (click)="onAddCustomer()"
                icon="pi pi-plus"
                label="   New Customer"
                styleClass="p-button-raised"
              ></p-button>
            </div>
          </p-toolbar>

          <gr-crud-data-table [config]="b2bCustomerConfig" [items]="customers">
            <ng-template #item let-value let-item="item" let-id="id">
              <!-- Shows a button for each item in the 'button' column. -->

              <div class="activeColumn" *ngIf="id === 'active'">
                <p-chip
                  [class]="value === true ? 'active' : 'inactive'"
                  [label]="value === true ? 'Active' : 'Inactive'"
                ></p-chip>
              </div>

              <div class="buttonsColumn" *ngIf="id === 'actions'">
                <p-button
                  type="button"
                  icon="pi pi-user"
                  (click)="onEditUsers(item)"
                  styleClass="p-button-success"
                  title="Modify users for customer"
                >
                </p-button>
                <p-button
                  type="button"
                  icon="pi pi-wrench"
                  (click)="onEditCustomer(item)"
                  styleClass="p-button-info"
                  title="Modify customer properties"
                >
                </p-button>
                <p-button
                  [disabled]="!item.active"
                  type="button"
                  icon="pi pi-key"
                  (click)="onShowApiModal(item)"
                  styleClass="p-button-help"
                  title="Modify API keys"
                >
                </p-button>

                <p-button
                  type="button"
                  icon="pi pi-power-off"
                  (click)="onChangeActivityStatus(item)"
                  styleClass="p-button-warning"
                  title="Enable/disable API access"
                >
                </p-button>

                <p-button
                  type="button"
                  icon="pi pi-trash"
                  (click)="onDeleteCustomer(item)"
                  styleClass="p-button-danger"
                  title="Delete customer"
                >
                </p-button>
              </div>

              <ng-container *ngIf="id !== 'actions' && id !== 'active'">
                {{ value }}
              </ng-container>
            </ng-template>
          </gr-crud-data-table>
        </div>
      </div>
    </div>
  </ng-container>
  <p-toast position="top-right">
    <ng-template let-message pTemplate="message">
      <div class="p-toast-message-content">
        <span class="p-toast-message-icon pi pi-info-circle"></span>
        <div class="toast-message-text">
          <div class="p-toast-summary">{{ message.summary }}</div>
          <div class="p-toast-detail" [innerHtml]="message.detail"></div>
        </div>
      </div>
    </ng-template>
  </p-toast>

  <p-dialog
    class="spinner-dialog"
    [closable]="false"
    [showHeader]="false"
    [(visible)]="displayProgress"
    [modal]="true"
  >
    <p-progressSpinner></p-progressSpinner>
  </p-dialog>

  <p-dialog
    [header]="customerModalHeader"
    [(visible)]="addCustomerModalVisibility"
    [dismissableMask]="true"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="customer-form">
      <gr-crud-data-editor
        [config]="customerConfig"
        [item]="defaultCustomerValues"
        (buttonClickEmitter)="
          customerState === 'edit' ? onEditCustomerSubmit($event) : onAddCustomerSubmit($event)
        "
      ></gr-crud-data-editor>
    </div>
  </p-dialog>

  <p-dialog
    [header]="confirmationDialogHeader"
    [(visible)]="confirmationDialogVisibility"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="confirmation-dialog">
      <div class="confirmation-dialog-text"><p [innerHTML]="confirmationDialogText"></p></div>
    </div>

    <ng-template pTemplate="footer">
      <div class="button-wrapper">
        <p-button
          icon="pi pi-times"
          (click)="confirmationDialogVisibility = false"
          label="  No"
          styleClass="p-button-danger"
        ></p-button>
        <p-button
          *ngIf="confirmAction === 'edit'"
          icon="pi pi-check"
          (click)="onConfirmEditCustomer()"
          label="  Yes"
          styleClass="p-button-success"
        ></p-button>

        <p-button
          *ngIf="confirmAction === 'status'"
          icon="pi pi-check"
          (click)="onConfirmActivityStatusChange()"
          label="  Yes"
          styleClass="p-button-success"
        ></p-button>

        <p-button
          *ngIf="confirmAction === 'delete'"
          icon="pi pi-check"
          (click)="onConfirmDeleteCustomer()"
          label="  Yes"
          styleClass="p-button-success"
        ></p-button>

        <p-button
          *ngIf="confirmAction === 'api_revoke'"
          icon="pi pi-check"
          (click)="onConfirmRevokeApiKey()"
          label="  Yes"
          styleClass="p-button-success"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog
    [header]="'Manage Customer API Keys'"
    [(visible)]="apiKeysModalVisibility"
    [modal]="true"
    [style]="{ width: '90vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="customer-dialog">
      <p-toolbar>
        <div class="p-toolbar-group-right">
          <p-button
            (click)="onShowAddApiModal()"
            icon="pi pi-plus"
            label="   Add new API"
            styleClass="p-button-raised"
          ></p-button>
        </div>
      </p-toolbar>

      <gr-crud-data-table [config]="apiConfig" [items]="apiKeys">
        <ng-template #item let-value let-item="item" let-id="id">
          <!-- Shows a button for each item in the 'button' column. -->
          <div class="buttonsColumn" *ngIf="id === 'actions'">
            <p-button
              type="button"
              icon="pi pi-trash"
              (click)="onRevokeApiKey(item)"
              styleClass="p-button-danger"
            >
            </p-button>
          </div>

          <ng-container *ngIf="id !== 'actions' && id !== 'active'">
            {{ value }}
          </ng-container>
        </ng-template>
      </gr-crud-data-table>
    </div>

    <ng-template pTemplate="footer">
      <div class="button-wrapper">
        <p-button
          (click)="apiKeysModalVisibility = false"
          label="Close"
          styleClass="p-button-danger"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Add new API key"
    [(visible)]="addApiModalVisibility"
    [dismissableMask]="true"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="api-form">
      <gr-crud-data-editor
        [config]="apiFormConfig"
        [item]="customerApiKeys"
        (buttonClickEmitter)="setApiKey(customerData, $event)"
      ></gr-crud-data-editor>
    </div>
  </p-dialog>

  <p-dialog
    header="New key"
    [(visible)]="showAPIModal"
    [dismissableMask]="true"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div style="overflow-wrap: anywhere">
      <h2>Please store key safely. It cannot be viewed again.</h2>
      <div class="key-container">
        <p class="key">{{ apiKey }}</p>

        <p-button (click)="onCopyKeyClick()">
          <i class="pi pi-copy" style="font-size: 2rem"></i>
        </p-button>
      </div>
    </div>
  </p-dialog>
</article>
