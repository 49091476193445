/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthenticationTokenList } from '../models/authentication-token-list';
import { AuthenticationTokenOut } from '../models/authentication-token-out';
import { B2BCustomerIn } from '../models/b-2-b-customer-in';
import { B2BCustomerOut } from '../models/b-2-b-customer-out';
import { B2BGetCustomersResponse } from '../models/b-2-b-get-customers-response';
import { B2BPutCustomer } from '../models/b-2-b-put-customer';

@Injectable({
  providedIn: 'root',
})
export class AdminCustomerB2BService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomers
   */
  static readonly GetCustomersPath = '/api/v1/b2b/admin/customer';

  /**
   * Get A List Of All Customers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<B2BGetCustomersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.GetCustomersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BGetCustomersResponse>;
      })
    );
  }

  /**
   * Get A List Of All Customers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: {
    context?: HttpContext
  }
): Observable<B2BGetCustomersResponse> {

    return this.getCustomers$Response(params).pipe(
      map((r: StrictHttpResponse<B2BGetCustomersResponse>) => r.body as B2BGetCustomersResponse)
    );
  }

  /**
   * Path part for operation postCustomer
   */
  static readonly PostCustomerPath = '/api/v1/b2b/admin/customer';

  /**
   * Create A New Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomer$Response(params: {
    context?: HttpContext
    body: B2BCustomerIn
  }
): Observable<StrictHttpResponse<B2BCustomerOut>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.PostCustomerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BCustomerOut>;
      })
    );
  }

  /**
   * Create A New Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomer(params: {
    context?: HttpContext
    body: B2BCustomerIn
  }
): Observable<B2BCustomerOut> {

    return this.postCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<B2BCustomerOut>) => r.body as B2BCustomerOut)
    );
  }

  /**
   * Path part for operation getCustomer
   */
  static readonly GetCustomerPath = '/api/v1/b2b/admin/customer/{erp_customer_id}';

  /**
   * Get Information Of A Specific Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer$Response(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<B2BCustomerOut>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.GetCustomerPath, 'get');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BCustomerOut>;
      })
    );
  }

  /**
   * Get Information Of A Specific Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<B2BCustomerOut> {

    return this.getCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<B2BCustomerOut>) => r.body as B2BCustomerOut)
    );
  }

  /**
   * Path part for operation putCustomer
   */
  static readonly PutCustomerPath = '/api/v1/b2b/admin/customer/{erp_customer_id}';

  /**
   * Replace Information About A Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomer$Response(params: {
    erp_customer_id: string;
    context?: HttpContext
    body: B2BPutCustomer
  }
): Observable<StrictHttpResponse<B2BCustomerOut>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.PutCustomerPath, 'put');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BCustomerOut>;
      })
    );
  }

  /**
   * Replace Information About A Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomer(params: {
    erp_customer_id: string;
    context?: HttpContext
    body: B2BPutCustomer
  }
): Observable<B2BCustomerOut> {

    return this.putCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<B2BCustomerOut>) => r.body as B2BCustomerOut)
    );
  }

  /**
   * Path part for operation deleteCustomer
   */
  static readonly DeleteCustomerPath = '/api/v1/b2b/admin/customer/{erp_customer_id}';

  /**
   * Delete An Existing Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer$Response(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.DeleteCustomerPath, 'delete');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete An Existing Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.deleteCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation deactivateCustomer
   */
  static readonly DeactivateCustomerPath = '/api/v1/b2b/admin/customer/{erp_customer_id}/deactivate';

  /**
   * Deactivate An Existing Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateCustomer$Response(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<B2BCustomerOut>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.DeactivateCustomerPath, 'post');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BCustomerOut>;
      })
    );
  }

  /**
   * Deactivate An Existing Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateCustomer(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<B2BCustomerOut> {

    return this.deactivateCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<B2BCustomerOut>) => r.body as B2BCustomerOut)
    );
  }

  /**
   * Path part for operation activateCustomer
   */
  static readonly ActivateCustomerPath = '/api/v1/b2b/admin/customer/{erp_customer_id}/activate';

  /**
   * Activate A Deactivated Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateCustomer$Response(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<B2BCustomerOut>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.ActivateCustomerPath, 'post');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BCustomerOut>;
      })
    );
  }

  /**
   * Activate A Deactivated Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateCustomer(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<B2BCustomerOut> {

    return this.activateCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<B2BCustomerOut>) => r.body as B2BCustomerOut)
    );
  }

  /**
   * Path part for operation getCustomerApiTokens
   */
  static readonly GetCustomerApiTokensPath = '/api/v1/b2b/admin/customer/{erp_customer_id}/token';

  /**
   * Get A List Of All Api Tokens Of A Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerApiTokens()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApiTokens$Response(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthenticationTokenList>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.GetCustomerApiTokensPath, 'get');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationTokenList>;
      })
    );
  }

  /**
   * Get A List Of All Api Tokens Of A Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerApiTokens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApiTokens(params: {
    erp_customer_id: string;
    context?: HttpContext
  }
): Observable<AuthenticationTokenList> {

    return this.getCustomerApiTokens$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationTokenList>) => r.body as AuthenticationTokenList)
    );
  }

  /**
   * Path part for operation postCustomerApiToken
   */
  static readonly PostCustomerApiTokenPath = '/api/v1/b2b/admin/customer/{erp_customer_id}/token';

  /**
   * Create A New Api Token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerApiToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerApiToken$Response(params: {
    erp_customer_id: string;
    token_name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.PostCustomerApiTokenPath, 'post');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
      rb.query('token_name', params.token_name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create A New Api Token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerApiToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerApiToken(params: {
    erp_customer_id: string;
    token_name: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.postCustomerApiToken$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCustomerApiToken
   */
  static readonly GetCustomerApiTokenPath = '/api/v1/b2b/admin/customer/{erp_customer_id}/token/{token_name}';

  /**
   * Get A Specific Customers Api Token By Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerApiToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApiToken$Response(params: {
    erp_customer_id: string;
    token_name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthenticationTokenOut>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.GetCustomerApiTokenPath, 'get');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
      rb.path('token_name', params.token_name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationTokenOut>;
      })
    );
  }

  /**
   * Get A Specific Customers Api Token By Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerApiToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApiToken(params: {
    erp_customer_id: string;
    token_name: string;
    context?: HttpContext
  }
): Observable<AuthenticationTokenOut> {

    return this.getCustomerApiToken$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationTokenOut>) => r.body as AuthenticationTokenOut)
    );
  }

  /**
   * Path part for operation revokeCustomerApiToken
   */
  static readonly RevokeCustomerApiTokenPath = '/api/v1/b2b/admin/customer/{erp_customer_id}/token/{token_name}/revoke';

  /**
   * Revoke A Specific Customers Api Token By Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeCustomerApiToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeCustomerApiToken$Response(params: {
    erp_customer_id: string;
    token_name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminCustomerB2BService.RevokeCustomerApiTokenPath, 'post');
    if (params) {
      rb.path('erp_customer_id', params.erp_customer_id, {});
      rb.path('token_name', params.token_name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Revoke A Specific Customers Api Token By Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revokeCustomerApiToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeCustomerApiToken(params: {
    erp_customer_id: string;
    token_name: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.revokeCustomerApiToken$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
