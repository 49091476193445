import { Component, OnInit } from '@angular/core';
import { AgentB2BService } from '../shared/services/api/services';
import { CrudTableConfig, TableCrudData } from '@gr/gr-component-library';
import { B2BAgentOrderOut } from '../shared/services/api/models';
import { DatePipe } from '@angular/common';

interface ProcessedAgentOrder {
  erp_customer_id: string;
  total_price_with_sales_tax: number;
  creation_date: string;
  display_date: string;
  order_number: string;
}

@Component({
  selector: 'app-sales-agent-overview',
  templateUrl: './sales-agent-overview.component.html',
  styleUrls: ['./sales-agent-overview.component.scss'],
})
export class SalesAgentOverviewComponent implements OnInit {
  constructor(private agentB2bService: AgentB2BService, private dataPipe: DatePipe) {}

  orderItems: ProcessedAgentOrder[] = [];
  filteredOrderItems: ProcessedAgentOrder[] = [];
  orderItemsCurrentMonth: ProcessedAgentOrder[] = [];
  monthlyTotal = 0;
  filteredTotal = 0;
  filterDate: Date[] = [];

  orderTableConfig: CrudTableConfig<TableCrudData> = {
    // Kunde, Preis, Datum, Bestellnummer
    fields: [
      {
        id: 'erp_customer_id',
        header: 'Kunde',
      },
      {
        id: 'total_price_with_sales_tax',
        header: 'Umsatz',
      },
      { id: 'display_date', header: 'Datum' },
      { id: 'order_number', header: 'Bestellnummer' },
    ],
  };

  ngOnInit(): void {
    this.agentB2bService.b2BGetAgentOrders().subscribe((response) => {
      this.processOrderItems(response);
      this.setMonthlyOrders();
      this.filterOrders();
    });
  }

  processOrderItems(orders: B2BAgentOrderOut[]): void {
    // Process the orders to display them in the table
    orders.sort((a, b) => {
      return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime();
    });
    const processedOrders: ProcessedAgentOrder[] = orders.map((order) => {
      const price = order.price?.total_price || 0;
      const taxedPrice = price / 1.19;
      const roundedPrice = Math.round(taxedPrice * 100) / 100;
      return {
        erp_customer_id: order.erp_customer_id,
        total_price_with_sales_tax: roundedPrice,
        creation_date: order.creation_date,
        display_date: this.dataPipe.transform(order.creation_date, 'short') as string,
        order_number: order.order_number,
      };
    });

    this.orderItems = processedOrders;
  }

  setMonthlyOrders(): void {
    this.orderItemsCurrentMonth = this.filterOrdersByCurrentMonth(this.orderItems);
    this.monthlyTotal = this.getTotalPrice(this.orderItemsCurrentMonth);
  }

  getTotalPrice(orders: ProcessedAgentOrder[]): number {
    let total = 0;
    for (const order of orders) {
      const orderPrice = order.total_price_with_sales_tax || 0;
      total += orderPrice;
    }

    return total;
  }

  filterOrdersByCurrentMonth(orders: ProcessedAgentOrder[]): ProcessedAgentOrder[] {
    const currentMonth = new Date().getMonth();
    const monthlyOrders = [];

    for (const order of orders) {
      const orderDate = new Date(order.creation_date);
      if (orderDate.getMonth() === currentMonth) {
        monthlyOrders.push(order);
      }
    }

    return monthlyOrders;
  }

  onFilterChange(): void {
    this.filterOrders(this.filterDate[0], this.filterDate[1]);
  }

  filterOrders(startDate?: Date, endDate?: Date): void {
    this.filteredOrderItems = this.orderItems.filter((order) => {
      const orderDate = new Date(order.creation_date);

      if (startDate && orderDate < startDate) return;
      if (endDate && orderDate > endDate) return;

      return true;
    });

    this.filteredTotal = this.getTotalPrice(this.filteredOrderItems);
  }
}
