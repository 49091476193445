/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ArticleTypes {
  Interior = 'interior',
  Door = 'door',
  Decor = 'decor',
  Handle = 'handle',
  Drawer = 'drawer'
}
