/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { B2BListAgentOrdersResponse } from '../models/b-2-b-list-agent-orders-response';
import { B2BListAgentsResponse } from '../models/b-2-b-list-agents-response';

@Injectable({
  providedIn: 'root',
})
export class AgentB2BService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation b2BGetAgents
   */
  static readonly B2BGetAgentsPath = '/api/v1/b2b/agent';

  /**
   * Get All Available B2B Agents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `b2BGetAgents()` instead.
   *
   * This method doesn't expect any request body.
   */
  b2BGetAgents$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<B2BListAgentsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AgentB2BService.B2BGetAgentsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BListAgentsResponse>;
      })
    );
  }

  /**
   * Get All Available B2B Agents.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `b2BGetAgents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  b2BGetAgents(params?: {
    context?: HttpContext
  }
): Observable<B2BListAgentsResponse> {

    return this.b2BGetAgents$Response(params).pipe(
      map((r: StrictHttpResponse<B2BListAgentsResponse>) => r.body as B2BListAgentsResponse)
    );
  }

  /**
   * Path part for operation b2BGetAgentOrders
   */
  static readonly B2BGetAgentOrdersPath = '/api/v1/b2b/agent/order';

  /**
   * Get B2B Orders For All Customers Assigned To An Agent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `b2BGetAgentOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  b2BGetAgentOrders$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<B2BListAgentOrdersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AgentB2BService.B2BGetAgentOrdersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BListAgentOrdersResponse>;
      })
    );
  }

  /**
   * Get B2B Orders For All Customers Assigned To An Agent.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `b2BGetAgentOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  b2BGetAgentOrders(params?: {
    context?: HttpContext
  }
): Observable<B2BListAgentOrdersResponse> {

    return this.b2BGetAgentOrders$Response(params).pipe(
      map((r: StrictHttpResponse<B2BListAgentOrdersResponse>) => r.body as B2BListAgentOrdersResponse)
    );
  }

}
