/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PdfKind {
  Hardware = 'hardware',
  Boards = 'boards',
  Manual = 'manual'
}
