import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {
  Article,
  ArticleReference,
  B2BCustomerIn,
  B2BCustomerOut,
  B2BGetCustomersResponse,
  ConstantsName,
  GlobalPriceConstant,
  PdfKind,
  PriceConstant,
  PrintoutLinkInfo,
} from 'src/app/shared/services/api/models';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/shared/services/api/services/admin.service';
import {
  AdminCustomerB2BService,
  AgentB2BService,
  OrdersService,
  PdfService,
} from 'src/app/shared/services/api/services';
import {
  CrudData,
  CrudEditorConfig,
  CrudTableConfig,
  TableCrudData,
} from '@gr/gr-component-library';
import { HttpErrorResponse } from '@angular/common/http';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { environment } from '../shared/environments/environment';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  providers: [MessageService],
})
export class AdminPanelComponent implements OnInit {
  //need to assign the enum to a variable to be able to use it in the template
  pdfKind = PdfKind;

  isB2BAdmin = false;

  display = false;

  displayProgress = false;
  itemToShow = NavItems.GLOBAL;

  // we need to set the this to access the customer data in the template as we can't run the setApiKey method in the onClick()
  // method of the SubmitButtonConfig due to a bug in the gr-component-library, https://greyrook.atlassian.net/browse/FC-57
  customerData = {} as B2BCustomerIn;

  subItemToShow = '';

  defaultValuesConfig = {} as CrudEditorConfig<GlobalConstantArticle>;
  defaultValues: GlobalConstantArticle = {};

  articleValuesConfig = {} as CrudEditorConfig<ConstantArticle>;
  articleValues: ConstantArticle = {};

  customerModalHeader = 'Add new customer';

  //because the b2b customer modal uses the same dialog for both add and edit,
  //we need to keep track of the state to select the correct methods
  //as this is not ideal, we should create a separate dialogs in the template
  customerState = 'add';

  customerConfig = {} as CrudEditorConfig<B2BCustomerIn>;
  defaultCustomerValues: B2BCustomerIn = {
    discount_multiplier: 1,
    markup_multiplier_b2b: 1,
    display_name: '',
    erp_customer_id: '',
    theme: '',
    agent_id: '',
  };

  navItems = [
    { name: NavItems.GLOBAL, text: 'Global' },
    { name: NavItems.ARTICLE, text: 'Article' },
    { name: NavItems.ORDERS, text: 'Orders' },
    { name: NavItems.CUSTOMERS, text: 'Customers' },
  ];
  articles: ArticleReference[] = [];
  articlesList: ArticleReference[] = [];
  currentArticle: Article = { name: '', constants: [] };

  categoriesList: string[] = [];

  orderTableConfig: CrudTableConfig<TableCrudData> = {
    fields: [
      {
        id: 'batchDownload',
        header: 'Batch',
      },
      { id: 'imos_order_no', header: 'Order ID' },
      { id: 'timeStamp', header: 'Date' },
      {
        id: 'boardsPdf',
        header: 'Boards PDF',
      },
      {
        id: 'hardwarePdf',
        header: 'Hardware PDF',
      },
      {
        id: 'manualPdf',
        header: 'Manual PDF',
      },
      {
        id: 'xml',
        header: 'XML',
      },
    ],
  };
  orderItems: OrderItem[] = [];

  addCustomerModalVisibility = false;

  customers: B2BGetCustomersResponse = [];
  customerToEdit: B2BCustomerIn = this.defaultCustomerValues;
  customerToDelete: B2BCustomerIn = this.defaultCustomerValues;
  customerToChangeActivty: B2BCustomerIn = this.defaultCustomerValues;

  b2bCustomerConfig: CrudTableConfig = {
    fields: [
      { id: 'display_name', header: 'Customer' },
      { id: 'erp_customer_id', header: 'ERP ID' },
      { id: 'discount_multiplier', header: 'Discount Multiplier' },
      { id: 'markup_multiplier_b2b', header: 'Markup Multiplier' },
      //Theme is not used at the moment, but will probably be used in the futur
      // { id: 'theme', header: 'Theme' },
      { id: 'active', header: 'Status' },
      {
        id: 'actions',
        header: 'Actions',
      },
    ],
  };

  confirmationDialogHeader = '';
  confirmationDialogVisibility = false;
  confirmationDialogText = '';
  confirmAction = '';

  apiKeys: AuthenticationTokenIn[] = [];
  apiKeysModalVisibility = false;
  customerApiKeys: AuthenticationTokenIn = {
    erp_customer_id: '',
    token_name: '',
  };

  apiFormConfig = {} as CrudEditorConfig<AuthenticationTokenIn>;
  apiModalHeader = '';
  addApiModalVisibility = false;
  showAPIModal = false;
  apiKey = '';
  apiKeyToDelete: AuthenticationTokenIn = this.customerApiKeys;

  apiConfig: CrudTableConfig = {
    fields: [
      { id: 'token_name', header: 'Token name' },
      {
        id: 'actions',
        header: 'Actions',
      },
    ],
  };

  salesAgents: AgentForForm[] = [];

  constructor(
    private adminService: AdminService,
    private keycloak: KeycloakService,
    private messageService: MessageService,
    private pdfService: PdfService,
    private orderService: OrdersService,
    private customerService: AdminCustomerB2BService,
    private agentB2bService: AgentB2BService
  ) {}

  ngOnInit(): void {
    this.keycloak.updateToken().then(() => {
      this.getdefaultValuesConfig();
      this.getArticleValuesConfig();

      this.adminService.getArticleList().subscribe((result) => {
        this.articles = result;

        result.forEach((item: ArticleReference) => {
          this.categoriesList.push(item.type);
        });

        this.categoriesList = [...new Set(this.categoriesList)];
      });

      this.agentB2bService.b2BGetAgents().subscribe((res) => {
        this.salesAgents = res.map((agent) => {
          return { label: agent.username ? agent.username : '', value: agent.id };
        });
        this.salesAgents.unshift({ label: 'None', value: null });
      });

      if (this.keycloak.getUserRoles().includes('b2b_admin')) this.isB2BAdmin = true;
    });
  }

  private async getdefaultValuesConfig(): Promise<void> {
    this.adminService.getGlobalConstants().subscribe(async (result) => {
      const fields: Array<CrudData | FormlyFieldConfig> = result.map((res) => {
        const label = `${res.name.toLowerCase().split('_').join(' ')} [${res.dimension}]`;
        const fieldConfig: FormlyFieldConfig = {
          key: res.name,
          type: 'number',
          props: { label: label, required: true },
          validation: {
            messages: {
              required: 'default values are required',
            },
          },
        };
        return fieldConfig;
      });

      result.forEach((res) => {
        this.defaultValues[res.name as string] = res.value;
      });
      this.defaultValuesConfig = {
        fields: fields,
        submitButtonConfig: {
          state: 'ready',
          stateConfigs: {
            ready: {
              label: 'Submit',
            },
          },
        },
      };
    });
  }

  private async getArticleValuesConfig(): Promise<void> {
    this.adminService.getGlobalConstants().subscribe(async (result) => {
      const fields: Array<CrudData | FormlyFieldConfig> = result.map((res) => {
        const label = `${res.name.toLowerCase().split('_').join(' ')} [${res.dimension}]`;
        const fieldConfig = {
          key: res.name,
          type: 'number',
          props: { label: label, required: false },
        };
        return fieldConfig;
      });
      this.articleValuesConfig = {
        fields: fields,
        submitButtonConfig: {
          state: 'ready',
          stateConfigs: {
            ready: {
              label: 'Submit',
            },
          },
        },
      };
    });
  }

  private async getOrders(): Promise<void> {
    this.pdfService.getPrintout().subscribe(async (res) => {
      res.forEach((order) => {
        this.orderItems.push({
          batchDownload: { ...order, shouldBatchDownload: false },
          imos_order_no: order.imos_order_no,
          // make timestamp look nice
          timeStamp: new Date(order.timestamp).toLocaleString(),
          boardsPdf: order,
          hardwarePdf: order,
        });
      });
    });
  }

  ondefaultValuesSubmit(model: GlobalConstantArticle): void {
    this.displayProgress = true;

    const requestData: GlobalPriceConstant[] = Object.entries(model).map((constant) => {
      console.log(constant);

      return {
        name: constant[0] as ConstantsName,
        value: constant[1],
      };
    });
    this.adminService.putGlobalConstants({ body: { constants: requestData } }).subscribe(
      () => {
        this.getdefaultValuesConfig();
        this.displayProgress = false;
      },
      (error) => {
        this.addWarning(error.error.detail[0].msg);
        this.displayProgress = false;
      }
    );
  }

  onArticleValuesSubmit(articleName: string, model: ConstantArticle): void {
    this.displayProgress = true;
    const requestData = Object.entries(model).map((res) => {
      return {
        name: res[0],
        value: res[1],
      };
    }) as PriceConstant[];

    this.adminService
      .putArticle({
        name: articleName,
        body: { name: articleName, constants: requestData },
      })
      .subscribe(
        () => {
          this.displayProgress = false;
        },
        (error) => {
          this.addWarning(error.error.detail[0].msg);
          this.displayProgress = false;
        }
      );
  }

  getArticleValueConstant(): void {
    const item: ConstantArticle = {};
    this.adminService
      .getArticle({ name: this.currentArticle.name })
      .subscribe(async (result: Article) => {
        result.constants.forEach((res) => {
          if (typeof res.value === 'number') item[res.name] = res.value ? res.value : null;
        });
        // we need to set the currentItem.
        //If we would just change the currentItem's values, the ChangeDetection won't be triggered.
        this.articleValues = item;
      });
  }

  async getArticles(category: string): Promise<void> {
    this.subItemToShow = category;

    this.articlesList = [];

    this.articles.forEach((articleName: ArticleReference) => {
      if (articleName.type === category) {
        this.articlesList.push(articleName);
      }
    });

    this.articlesList = this.articlesList.map((al) => {
      return { ...al, display_name: al.display_name || al.name };
    });
    this.articlesList.sort((e1, e2) =>
      (e1.display_name || e1.name) < (e2.display_name || e2.name) ? -1 : 1
    );
  }

  async getCustomers(): Promise<void> {
    this.customerService.getCustomers().subscribe(async (res) => {
      this.customers = res;
    });
  }

  onAddCustomerSubmit(customerData: B2BCustomerIn): void {
    this.displayProgress = true;
    this.customerService.postCustomer({ body: customerData }).subscribe({
      next: () => {
        this.getCustomers();
        this.addCustomerModalVisibility = false;
        this.displayProgress = false;
        this.showTemporaryToast(
          'success',
          'Added successfully',
          'New customer has been added successfully.'
        );
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
        this.addCustomerModalVisibility = false;
        this.displayProgress = false;
        this.showTemporaryToast(
          'error',
          'Error',
          error.error.detail ? error.error.detail : 'unknown error'
        );
      },
    });
  }

  onEditCustomerSubmit(customerData: B2BCustomerIn): void {
    this.confirmAction = 'edit';
    this.customerToEdit = customerData;
    this.confirmationDialogVisibility = true;
    this.confirmationDialogHeader = 'Edit customer confirmation';
    this.confirmationDialogText =
      'Are you sure you want to change customer details: <b>' + customerData.display_name + '</b>?';
  }

  onConfirmEditCustomer(): void {
    this.displayProgress = true;

    this.customerService
      .putCustomer({
        erp_customer_id: this.customerToEdit.erp_customer_id,
        body: {
          display_name: this.customerToEdit.display_name,
          discount_multiplier: this.customerToEdit.discount_multiplier,
          markup_multiplier_b2b: this.customerToEdit.markup_multiplier_b2b,
          agent_id: this.customerToEdit.agent_id,
          theme: this.customerToEdit.theme,
        },
      })
      .subscribe({
        next: () => {
          this.getCustomers();
          this.addCustomerModalVisibility = false;
          this.confirmationDialogVisibility = false;
          this.displayProgress = false;
          this.showTemporaryToast(
            'success',
            'Edit successful',
            'Customer details have been changed.'
          );
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.addCustomerModalVisibility = false;
          this.confirmationDialogVisibility = false;
          this.displayProgress = false;
          this.showTemporaryToast(
            'error',
            'Error',
            error.error.detail ? error.error.detail : 'unknown error'
          );
        },
      });
  }

  onDeleteCustomer(customerData: B2BCustomerIn): void {
    this.confirmAction = 'delete';
    this.customerToDelete = customerData;
    this.confirmationDialogVisibility = true;
    this.confirmationDialogHeader = 'Delete customer confirmation';
    this.confirmationDialogText =
      'Are you sure you want to delete customer: <b>' +
      customerData.display_name +
      '</b>? This change cannot be undone.';
  }

  onConfirmDeleteCustomer(): void {
    this.displayProgress = true;
    this.customerService
      .deleteCustomer({ erp_customer_id: this.customerToDelete.erp_customer_id })
      .subscribe({
        next: () => {
          this.getCustomers();
          this.confirmationDialogVisibility = false;
          this.displayProgress = false;
          this.showTemporaryToast('info', 'delete successful', 'Customer has been deleted.');
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.confirmationDialogVisibility = false;
          this.displayProgress = false;
          this.showTemporaryToast(
            'error',
            'Error',
            error.error.detail ? error.error.detail : 'unknown error'
          );
        },
      });
  }

  onChangeActivityStatus(customerData: B2BCustomerIn): void {
    this.customerData = customerData;
    this.confirmAction = 'status';
    this.customerToChangeActivty = customerData;
    this.customerToDelete = customerData;
    this.confirmationDialogVisibility = true;

    if (customerData.active) {
      this.confirmationDialogHeader = 'Deactivate ' + customerData.display_name + '?';
      this.confirmationDialogText =
        'Are you sure you want to deactivate customer: <b>' +
        customerData.display_name +
        '</b>? </br> This will <b>delete all tokens</b> associated with this customer.';
    } else {
      this.confirmationDialogHeader = 'Activate ' + customerData.display_name + '?';
      this.confirmationDialogText =
        'Are you sure you want to activate customer: <b>' + customerData.display_name + '</b>?';
    }
  }

  onConfirmActivityStatusChange(): void {
    this.displayProgress = true;

    if (this.customerData.active) {
      this.customerService
        .deactivateCustomer({
          erp_customer_id: this.customerToChangeActivty.erp_customer_id,
        })
        .subscribe({
          next: () => {
            this.displayProgress = false;
            this.getCustomers();
            this.confirmationDialogVisibility = false;

            this.showTemporaryToast(
              'info',
              'Deactivation successfull',
              'Customer has been deactivated successfully.'
            );
          },
          error: (error: HttpErrorResponse) => {
            console.error(error);
            this.confirmationDialogVisibility = false;
            this.displayProgress = false;
            this.showTemporaryToast(
              'error',
              'Error',
              error.error.detail ? error.error.detail : 'unknown error'
            );
          },
        });
    } else {
      this.customerService
        .activateCustomer({
          erp_customer_id: this.customerToChangeActivty.erp_customer_id,
        })
        .subscribe({
          next: () => {
            this.displayProgress = false;
            this.getCustomers();
            this.confirmationDialogVisibility = false;

            this.showTemporaryToast(
              'info',
              'Activation successfull',
              'Customer has been activated successfully.'
            );
          },
          error: (error: HttpErrorResponse) => {
            console.error(error);
            this.confirmationDialogVisibility = false;
            this.displayProgress = false;
            this.showTemporaryToast(
              'error',
              'Error',
              error.error.detail ? error.error.detail : 'unknown error'
            );
          },
        });
    }
  }

  onEditCustomer(item: B2BCustomerIn): void {
    this.customerState = 'edit';
    this.customerModalHeader = 'Edit Customer ' + item.display_name;
    this.customerConfig = {
      fields: [...this.commonCustomerFields],
      submitButtonConfig: {
        state: 'ready',
        stateConfigs: {
          ready: {
            label: 'Submit',
          },
        },
      },
    };

    this.defaultCustomerValues = item;
    this.addCustomerModalVisibility = true;
  }

  onEditUsers(b2bCustomer: B2BCustomerOut): void {
    const url = `${environment.keycloak.serverUrl}/admin/${environment.keycloak.realmName}/console/#/${environment.keycloak.realmName}/groups/${b2bCustomer.keycloak_group_id}`;
    window.open(url, '_blank');
  }

  onShowAddApiModal(): void {
    this.addApiModalVisibility = true;
  }

  getApiKeys(id: string): void {
    this.customerService.getCustomerApiTokens({ erp_customer_id: id }).subscribe({
      next: (res) => {
        this.apiKeys = res;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
        this.showTemporaryToast(
          'error',
          'Error',
          error.error.detail ? error.error.detail : 'unknown error'
        );
      },
    });
  }

  onShowApiModal(customerData: B2BCustomerIn): void {
    this.customerData = customerData;
    this.apiKeysModalVisibility = true;

    this.getApiKeys(customerData.erp_customer_id);

    this.apiFormConfig = {
      fields: [
        {
          key: 'token_name',
          type: 'string',
          props: { label: 'Token name', required: true },
          validation: {
            messages: {
              required: 'Token is required',
            },
          },
        },
      ],
      submitButtonConfig: {
        state: 'ready',
        stateConfigs: {
          ready: {
            label: 'Submit',
          },
        },
      },
    };
  }

  setApiKey(customerData: B2BCustomerIn, apiData: AuthenticationTokenIn): void {
    this.displayProgress = true;

    this.customerService
      .postCustomerApiToken({
        erp_customer_id: customerData.erp_customer_id,
        token_name: apiData.token_name,
      })
      .subscribe({
        next: (res) => {
          this.getApiKeys(customerData.erp_customer_id);
          this.addApiModalVisibility = false;
          this.displayProgress = false;
          this.apiKey = res;
          this.showAPIModal = true;
          this.showTemporaryToast(
            'success',
            'Added successfully',
            'New Api Key has been successfully added.'
          );
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.showTemporaryToast(
            'error',
            'Error',
            error.error.detail ? error.error.detail : 'unknown error'
          );
          this.displayProgress = false;
        },
      });
  }

  onRevokeApiKey(apiKey: AuthenticationTokenIn): void {
    this.confirmAction = 'api_revoke';
    this.apiKeyToDelete = apiKey;
    this.confirmationDialogVisibility = true;
    this.confirmationDialogHeader = 'Revoke API key confirmation';
    this.confirmationDialogText =
      'Are you sure you want to Revoke API key: <b>' +
      apiKey.token_name +
      '</b>? This change cannot be undone.';
  }

  onConfirmRevokeApiKey(): void {
    this.displayProgress = true;
    this.customerService
      .revokeCustomerApiToken({
        erp_customer_id: this.apiKeyToDelete.erp_customer_id,
        token_name: this.apiKeyToDelete.token_name,
      })
      .subscribe({
        next: () => {
          this.getApiKeys(this.apiKeyToDelete.erp_customer_id);
          this.confirmationDialogVisibility = false;
          this.displayProgress = false;
          this.showTemporaryToast('info', 'Revoke successful', 'Api key has been revoked.');
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.displayProgress = false;
          this.showTemporaryToast(
            'error',
            'Error',
            error.error.detail ? error.error.detail : 'unknown error'
          );
        },
      });
  }

  onCopyKeyClick(): void {
    navigator.clipboard.writeText(this.apiKey);
    this.showTemporaryToast('info', 'Copied', 'API key copied to clipboard.');
  }

  sideNavClick(item: NavItems): void {
    if (item === NavItems.ORDERS) {
      this.orderItems = [];
      this.getOrders();
    }

    if (item === NavItems.CUSTOMERS) {
      this.getCustomers();
    }

    this.itemToShow = item;
    this.display = false;
  }

  onAddCustomer(): void {
    this.customerState = 'add';

    this.defaultCustomerValues = {
      active: true,
      discount_multiplier: 1,
      markup_multiplier_b2b: 1,
      display_name: '',
      erp_customer_id: '',
      theme: '',
      agent_id: '',
    };

    this.customerConfig = {
      fields: [
        {
          key: 'erp_customer_id',
          type: 'input',
          props: { label: 'ERP customer ID', required: true },
          validation: {
            messages: {
              required: 'ID is required',
            },
          },
        },
        ...this.commonCustomerFields,
      ],
      submitButtonConfig: {
        state: 'ready',
        stateConfigs: {
          ready: {
            label: 'Submit',
          },
        },
      },
    };

    this.addCustomerModalVisibility = true;
  }

  get commonCustomerFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'display_name',
        type: 'string',
        props: { label: 'Display name', required: true },
        validation: {
          messages: {
            required: 'Name is required',
          },
        },
      },
      {
        key: 'discount_multiplier',
        type: 'number',
        props: { label: 'Discount multiplier', required: true },
        validation: {
          messages: {
            required: 'Discount multiplier is required',
          },
        },
      },
      {
        key: 'markup_multiplier_b2b',
        type: 'number',
        props: { label: 'Markup multiplier', required: true },
        validation: {
          messages: {
            required: 'Markup multiplier is required',
          },
        },
      },
      {
        key: 'agent_id',
        type: 'dropdown',
        className: 'agent-select',
        props: {
          options: this.salesAgents,
          filter: true,
          filterBy: 'label',
          appendTo: 'body',
          label: 'Handelsvertreter',
        },
      },
      //Theme is not used at the moment, but will probably be used in the futur
      // {
      //   key: 'theme',
      //   type: 'string',
      //   props: { label: 'Theme', required: true },
      //   validation: {
      //     messages: {
      //       required: 'Theme is required',
      //     },
      //   },
      // },
    ];
  }

  private showTemporaryToast(severity: string, summary: string, detail: string): void {
    this.messageService.add({
      sticky: false,
      severity: severity,
      summary: summary,
      detail: detail,
      life: 15000,
    });
  }

  private addWarning(warning: string): void {
    this.messageService.add({
      sticky: false,
      severity: 'info',
      summary: 'Hinweis',
      detail: warning,
      life: 15000,
    });
  }

  batchDownload(): void {
    this.orderItems
      .filter((order) => order.batchDownload.shouldBatchDownload)
      .forEach((order) => {
        this.downloadPdf(order, PdfKind.Hardware);
        this.downloadPdf(order, PdfKind.Boards);
      });
  }

  downloadPdf(order: OrderItem, kind: PdfKind): void {
    this.pdfService.getPrintoutPdf({ imos_order_no: order.imos_order_no, kind: kind }).subscribe({
      next: (res) => {
        const blob = res as unknown as Blob;
        const filename = `${order.imos_order_no}-${kind}.pdf`;
        this.download(blob, filename);
      },
      error: (error: HttpErrorResponse) => {
        this.showTemporaryToast(
          'error',
          'PDF download failed',
          error.error.detail ? error.error.detail : 'unknown error'
        );
      },
    });
  }

  downloadXML(order: OrderItem): void {
    //Typecasting order_id as number because multiple methods in the backend who accept the order_id uses strings or numbers, until we didn't align this we need this workaround
    this.pdfService.getOrderXml({ imos_order_no: order.imos_order_no }).subscribe({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore API type says `string` but service actually returns a `Blob`
      next: (blob: Blob) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;

        link.download = `${order.imos_order_no}.xml`;
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
      },
      error: (error: HttpErrorResponse) => {
        this.showTemporaryToast(
          'error',
          'XML download failed',
          error.error.detail ? error.error.detail : 'unknown error'
        );
      },
    });
  }

  private download(blob: Blob, filename: string): void {
    const file = new Blob([blob], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  logout(): void {
    this.keycloak.logout();
  }
}

export enum NavItems {
  'GLOBAL',
  'ARTICLE',
  'ORDERS',
  'CUSTOMERS',
}

interface ConstantArticle {
  [key: string]: number | null;
}

interface GlobalConstantArticle {
  [key: string]: number;
}

export interface OrderItem {
  batchDownload: PrintoutLinkInfoBatchable;
  imos_order_no: string;
  timeStamp: string;
  boardsPdf: PrintoutLinkInfo;
  hardwarePdf: PrintoutLinkInfo;
}

interface PrintoutLinkInfoBatchable extends PrintoutLinkInfo {
  shouldBatchDownload: boolean;
}

export interface AuthenticationTokenIn {
  erp_customer_id: string;
  token_name: string;
}
interface AgentForForm {
  label: string;
  value: string | null;
}
