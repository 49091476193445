/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  template: `
    <p-dropdown
      [options]="opt"
      [filter]="props['filter']"
      [filterBy]="props['filterBy']"
      [appendTo]="props['appendTo']"
      [ngModel]="formControl.value"
      (ngModelChange)="onValueChange($event)"
    >
    </p-dropdown>
  `,
})
export class CustomDropdownComponent extends FieldType<FieldTypeConfig> {
  get opt(): any[] | undefined {
    if (this.props.options === null) {
      return undefined;
    }
    return this.props.options as any[];
  }
  onValueChange(value: any): void {
    this.formControl.setValue(value);
  }
}
