<div class="wrapper">
  <div class="monthly-wrapper">
    <h1>Diesen Monat</h1>
    <div class="content">
      <div class="line">
        <div class="col left">Anzahl Bestellungen:</div>
        <div class="col right">{{ orderItemsCurrentMonth.length }}</div>
      </div>
      <div class="line">
        <div class="col left">Umsatz:</div>
        <div class="col right">{{ monthlyTotal | number : '1.2-2' : 'de' }} €</div>
      </div>
    </div>
  </div>

  <hr />

  <div class="table-wrapper">
    <h1>Bestellungen</h1>
    <div class="date-chooser-wrapper">
      <span>Datumsbereich: </span
      ><p-calendar
        [(ngModel)]="filterDate"
        selectionMode="range"
        [showButtonBar]="true"
        (ngModelChange)="onFilterChange()"
      />
      <span class="filtered-total">Anzahl Bestellungen: {{ filteredOrderItems.length }} </span>
      <span class="filtered-total">Umsatz: {{ filteredTotal | number : '1.2-2' : 'de' }} €</span>
    </div>

    <gr-crud-data-table [config]="orderTableConfig" [items]="filteredOrderItems">
    </gr-crud-data-table>
  </div>
</div>
