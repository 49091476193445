/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Article } from '../models/article';
import { ArticleReference } from '../models/article-reference';
import { GlobalConstants } from '../models/global-constants';
import { GlobalPriceConstant } from '../models/global-price-constant';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getArticleList
   */
  static readonly GetArticleListPath = '/api/v1/admin/articles';

  /**
   * Get Article Name List.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleList$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ArticleReference>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetArticleListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ArticleReference>>;
      })
    );
  }

  /**
   * Get Article Name List.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArticleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleList(params?: {
    context?: HttpContext
  }
): Observable<Array<ArticleReference>> {

    return this.getArticleList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArticleReference>>) => r.body as Array<ArticleReference>)
    );
  }

  /**
   * Path part for operation getArticle
   */
  static readonly GetArticlePath = '/api/v1/admin/articles/{name}';

  /**
   * Get Article Constants By Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticle$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetArticlePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * Get Article Constants By Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticle(params: {
    name: string;
    context?: HttpContext
  }
): Observable<Article> {

    return this.getArticle$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * Path part for operation putArticle
   */
  static readonly PutArticlePath = '/api/v1/admin/articles/{name}';

  /**
   * Defines Article Constants By Using Article Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putArticle$Response(params: {
    name: string;
    context?: HttpContext
    body: Article
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.PutArticlePath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Defines Article Constants By Using Article Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putArticle(params: {
    name: string;
    context?: HttpContext
    body: Article
  }
): Observable<any> {

    return this.putArticle$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getGlobalConstants
   */
  static readonly GetGlobalConstantsPath = '/api/v1/admin/global_constants';

  /**
   * Get Settings For Global Constants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConstants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConstants$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GlobalPriceConstant>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetGlobalConstantsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GlobalPriceConstant>>;
      })
    );
  }

  /**
   * Get Settings For Global Constants.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGlobalConstants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConstants(params?: {
    context?: HttpContext
  }
): Observable<Array<GlobalPriceConstant>> {

    return this.getGlobalConstants$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GlobalPriceConstant>>) => r.body as Array<GlobalPriceConstant>)
    );
  }

  /**
   * Path part for operation putGlobalConstants
   */
  static readonly PutGlobalConstantsPath = '/api/v1/admin/global_constants';

  /**
   * Change Settings For Global Constants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putGlobalConstants()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putGlobalConstants$Response(params: {
    context?: HttpContext
    body: GlobalConstants
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.PutGlobalConstantsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Change Settings For Global Constants.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putGlobalConstants$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putGlobalConstants(params: {
    context?: HttpContext
    body: GlobalConstants
  }
): Observable<any> {

    return this.putGlobalConstants$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
